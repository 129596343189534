<template>
  <div class="editor__control">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "EControl",
};
</script>

<style lang="sass">
.editor
  &__box
    background: #47474F
    color: #fff
    border-radius: 3px
    padding: 10px 15px
  &__control
    position: absolute
    z-index: 100
    visibility: hidden
    transition: all 150ms ease-in
    border-radius: 2px
    color: #ffffff

    &.--active
      visibility: visible

    &.--collages
      padding: 0
      width: 200px
      background: transparent
      color: #333
      top: 50px
      left: 10px

    &.--content
      width: 350px
      bottom: 30px
      right: 10px
      padding: 10px 15px
      border-radius: 5px
      overflow: hidden
      background: #47474F

    &.--stat
      top: 60px
      right: 10px
      width: 200px
      color: #ffffff

    &.--object
      bottom: 30px
      left: 10px
      width: 250px
      z-index: 190

    &.--action
      top: calc(50% + 430px)
      left: 50%
      transform: translateX(-50%)
      z-index: 100
</style>
