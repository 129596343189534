<template>
  <div class="object-list">
    <div
      v-for="(object, idx) in objects"
      :key="idx"
      class="object-list__item"
      :class="{ '--active': idx === idxActive }"
      @click="emit('click', idx)"
    >
      <div class="object-list__item-title">{{ object.id }} {{ object.name }}</div>
      <div class="object-list__item-action">
        <span @click.stop="emit('delete', idx)"><i class="far fa-trash-alt"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EObjectList",
  props: ["objects", "idxActive"],
  data: function() {
    return {
      // idxActive: null
    };
  },
  methods: {
    emit: function(event, idx) {
      const object = [...this.objects].splice(idx, 1);
      const val = object[0].id ? object[0].id : idx;
      this.$emit(event, idx);
    },
  },
};
</script>

<style lang="sass">
.object-list
  margin-top: 20px
  background: #007AFF
  color: #fff
  border-radius: 3px
  overflow: hidden
  &__item
    font-size: 13px
    padding: 5px 10px
    border-bottom: 1px solid #2684ef
    display: flex
    justify-content: space-between
    &:hover
      cursor: pointer
    &:last-child
      border-bottom: none
    &.--active
      background: #fff
      color: #007AFF
</style>
