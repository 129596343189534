<template>
  <div class="object-form">
    <div class="object-form__position mb-2">
      <div
        v-for="position in ['left', 'right', 'top', 'bottom', 'reset']"
        :key="position"
        class="object-form__position-item"
        :class="{ '--active': editableObject.class.includes(position) }"
        @click="setObjectClass(position)"
      >
        {{ position }}
      </div>
    </div>
    <div class="object-form__fields">
      <div class="row mb-2">
        <div class="col">
          Начальная точка
        </div>
      </div>
      <div class="row">
        <div class="col">
          <v-input-num v-model.number="editableObject.start.top" label="Отступ" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.start.w" label="Ширина" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.start.h" label="Высота" dimension="px"></v-input-num>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <v-input-num v-model.number="editableObject.start.x" label="X" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.start.y" label="Y" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.start.r" label="Угол" dimension="°"></v-input-num>
        </div>
      </div>

      <div class="row mb-2 mt-3">
        <div class="col">
          Конечная точка
        </div>
      </div>
      <div class="row">
        <div class="col">
          <v-input-num v-model.number="editableObject.end.top" label="Отсуп" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.end.w" label="Ширина" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.end.h" label="Высота" dimension="px"></v-input-num>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <v-input-num v-model.number="editableObject.end.x" label="X" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.end.y" label="Y" dimension="px"></v-input-num>
        </div>
        <div class="col">
          <v-input-num v-model.number="editableObject.end.r" label="Угол" dimension="°"></v-input-num>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VInputNum from "@/components/basic/v-input-num";

export default {
  name: "EObjectForm",
  components: { VInputNum },
  props: ["editableObject"],
  methods: {
    setObjectClass: function(name) {
      this.$emit("set-class", name);
    },
  },
};
</script>

<style lang="sass">
.object-form
  display: flex
  flex-direction: column
  background: #47474F
  color: #fff
  border-radius: 5px
  overflow: hidden

  &__position
    padding: 0 10px
    background: #007AFF
    display: flex
    height: 35px
    font-size: 13px
    justify-content: space-around
    align-items: center

    &-item
      padding: 1px 6px
      border-radius: 3px

      &:hover
        cursor: pointer
        background: #4b96fa

      &.--active
        background: #ffffff
        color: #007AFF

  &__fields
    padding: 10px 15px 10px 25px

    .col
      padding: 0
</style>
