<template>
  <div class="editor-collage">
    <e-control class="editor__control --collages --active">
      <div>
        <span class="btn btn-sm me-2 btn-primary" @click="createCollage">Добавить</span>
        <span class="btn btn-sm me-2 btn-success" @click="saveCollage" v-if="editableCollage">Сохранить</span>
      </div>
      <e-object-list
        :objects="collages"
        :idx-active="idxEditableCollage"
        @click="setCollage"
        @delete="deleteCollage"
      ></e-object-list>

      <div v-if="editableCollage" class="mt-5 editor__box">
        <div class="mb-2">
          <v-input v-model="editableCollage.name" label="Название коллажа"></v-input>
        </div>
        <div class="mb-2">
          <v-input v-model="editableCollage.description" label="Описание"></v-input>
        </div>
        <div class="mb-2">
          <v-input v-model.number="editableCollage.sort" label="Сортировка"></v-input>
        </div>
        <div>
          <v-input v-model="editableCollage.preview" label="Путь до превью (листинг)"></v-input>
        </div>
        <div>
          <v-input v-model="editableCollage.icon" label="Путь до иконки (листинг)"></v-input>
        </div>
        <div>
          <v-input v-model="editableCollage.bgPath" label="Путь до фона D"></v-input>
        </div>
        <div>
          <v-input v-model="editableCollage.bgPathMobile" label="Путь до фона M"></v-input>
        </div>
      </div>
    </e-control>

    <div v-if="editableCollage" class="editor" @click="resetEditable">
      <div :class="['editor__collage', `--${device}`]">
        <div :class="['collage']">
          <collage-background :device="device" :collage="{bgPath: editableCollage.bgPath, bgPathMobile: editableCollage.bgPathMobile}"></collage-background>
          <collage-object
              :objects="editableCollage.objects"
              :device="device"
              :top="top"
              :editable="true"
              @set="setEditableObject"
          >
          </collage-object>
          <template v-if="editableObject">
            <v-resizable
              :position="editableObject.class"
              :width="editableObject.start.w"
              :height="editableObject.start.h"
              :top="editableObject.start.y"
              :left="editableObject.start.x"
              @resize:end="resizeStartObject"
              @drag:end="resizeStartObject"
              >START
            </v-resizable>

            <v-resizable
              :position="editableObject.class"
              :width="editableObject.end.w"
              :height="editableObject.end.h"
              :top="editableObject.end.y"
              :left="editableObject.end.x"
              @resize:end="resizeEndObject"
              @drag:end="resizeEndObject"
              >END
            </v-resizable>
          </template>
          <c-phone>
            <v-scroller v-model.number="top" :ext="800" @end="isEnd = true"></v-scroller>
            <collage-content
                :content="editableCollage.texts"
                :idx-editable-content="idxEditableContent"
                :editable="true"
                :isEnd="isEnd"
                :top="top"
                @set="setEditableContent"
                @delete="deleteText"
            >
            </collage-content>
          </c-phone>
        </div>
      </div>

      <e-control :class="['--stat', '--active']">
        <div class="row">
          <div class="col">
            Отступ<br />
            {{ top }}
          </div>
          <div class="col">
            Высота<br />
            {{ $refs.scroll ? $refs.scroll.scrollHeight : 0 }}
          </div>
        </div>
        <div class="mt-3">
          <span class="btn btn-sm btn-primary me-2" @click="device = 'desktop'">Desktop</span>
          <span class="btn btn-sm btn-primary me-2" @click="device = 'tablet'">Tablet</span>
          <span class="btn btn-sm btn-primary" @click="device = 'mobile'">Mobile</span>
        </div>
        <e-object-list
          :objects="editableCollage.objects[device]"
          :idx-active="idxEditableObject"
          @click="setEditableObject"
          @delete="deleteObject"
        >
        </e-object-list>
        <div v-if="editableObject" class="mt-5 editor__box">
          <div class="mb-2">
            <v-input v-model="editableObject.name" label="Название"></v-input>
          </div>
          <div>
            <v-input v-model="editableObject.imgPath" label="Путь до файла"></v-input>
          </div>
        </div>
      </e-control>

      <e-control v-if="editableObject" :class="['--object', '--no-bg', { '--active': editableObject }]">
        <e-object-form :editable-object="editableObject" @set-class="setObjectClass"></e-object-form>
      </e-control>

      <e-control class="editor__control --action --active --no-bg">
        <span class="btn btn-md me-2 btn-primary" @click="addText">Добавить текст</span>
        <span class="btn btn-md me-2 btn-primary" @click="addSound">Добавить звук</span>
        <span class="btn btn-md btn-primary" @click="addObject">Добавить объект</span>
      </e-control>

      <e-control :class="['--content', { '--active': typeof editableContent.top !== 'undefined' }]">
        <h4 class="mb-3">Редактирование {{editableContent.sound ? 'звука' : 'текста'}}</h4>
        <v-textarea v-if="!editableContent.sound" v-model="editableContent.content" :rows="10"></v-textarea>
        <v-input v-else v-model="editableContent.content"></v-input>
        <v-input-num v-model="editableContent.top"></v-input-num>
        <v-input-num v-if="editableContent.sound" v-model="editableContent.left"></v-input-num>
      </e-control>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import VTextarea from "@/components/basic/v-textarea";
import CPhone from "@/components/phone";
import VInputNum from "@/components/basic/v-input-num";
import EObjectForm from "@/components/editor/collage/e-object-form";
import EControl from "@/components/editor/collage/e-control";
import EObjectList from "@/components/editor/collage/e-object-list";
import VInput from "@/components/basic/v-input";
import VResizable from "@/components/basic/v-resizeble";
import CollageContent from "@/components/collage/collage-content";
import VScroller from "@/components/v-scroller";
import CollageObject from "@/components/collage/collage-object";
import CollageBackground from "@/components/collage/collage-background";

export default {
  name: "EditorCollagePage",
  components: {
    CollageBackground,
    CollageObject,
    VScroller,
    CollageContent, VResizable, VInput, EObjectList, EControl, EObjectForm, VInputNum, CPhone, VTextarea },
  data: function () {
    return {
      device: "desktop",
      collages: [],
      isSaved: true,
      top: 0,
      isEnd: false,
      idEditableCollage: null,
      idxEditableCollage: null,
      idxEditableContent: null,
      idxEditableObject: null,
    };
  },
  computed: {
    editableContent: function() {
      if (!this.idxEditableContent === null || !this.editableCollage === null) return null;
      const a = this.editableCollage.texts.filter((_, idx) => idx === this.idxEditableContent);
      return a.length ? a[0] : {};
    },
    editableObject: function() {
      if (this.idxEditableObject === null) return null;
      let object = this.editableCollage.objects[this.device].filter((_, idx) => idx === this.idxEditableObject);
      if (!object.length) return null;
      object = object[0];
      object.compClass = object.class.map(c => "--" + c);

      object.start.compStyle = {
        transform: `translate(${object.start.x}px, ${object.start.y}px) rotate(${object.start.r}deg)`,
        height: `${object.start.h}px`,
        width: `${object.start.w}px`,
      };

      object.end.compStyle = {
        transform: `translate(${object.end.x}px, ${object.end.y}px) rotate(${object.end.r}deg)`,
        height: `${object.end.h}px`,
        width: `${object.end.w}px`,
      };

      return object;
    },
    editableCollage: function() {
      if (!this.idEditableCollage === null) return null;
      const a = this.collages.filter((_, idx) => _.id === this.idEditableCollage);
      return a.length ? a[0] : null;
    },
  },
  async mounted() {
    this.collages = await api.loadCollage();
    this.$store.commit("setBackground", "editor");
    this.$store.commit("setShowPhoneOpacity", false);
    // this.$refs.scroll.scrollHeight
  },
  methods: {
    resizeStartObject: function(e) {
      this.editableObject.start.h = e.height;
      this.editableObject.start.w = e.width;
      this.editableObject.start.x = e.left;
      this.editableObject.start.y = e.top;
    },
    resizeEndObject: function(e) {
      this.editableObject.end.h = e.height;
      this.editableObject.end.w = e.width;
      this.editableObject.end.x = e.left;
      this.editableObject.end.y = e.top;
    },
    addObject: function() {
      this.editableCollage.objects[this.device].push({
        name: "",
        imgPath: "1",
        class: ["left", "top"],
        start: { top: 100, w: 100, h: 100, x: 100, y: 100, r: 0 },
        end: { top: 400, w: 100, h: 100, x: 300, y: 300, r: 0 },
      });
      this.idxEditableObject = this.editableCollage.objects[this.device].length - 1;
    },
    resetPosition: function() {
      this.editableObject.start.x = 0;
      this.editableObject.start.y = 0;
      this.editableObject.end.x = 0;
      this.editableObject.end.y = 0;
      return true;
    },
    setObjectClass: function(name) {
      if (!this.editableObject) return;
      if (name === "reset") return this.resetPosition();
      if (!this.editableObject.class.includes(name)) this.editableObject.class.push(name);
      if (name === "left") this.editableObject.class = this.editableObject.class.filter(n => n !== "right");
      if (name === "right") this.editableObject.class = this.editableObject.class.filter(n => n !== "left");
      if (name === "top") this.editableObject.class = this.editableObject.class.filter(n => n !== "bottom");
      if (name === "bottom") this.editableObject.class = this.editableObject.class.filter(n => n !== "top");
    },
    resetEditable: function(e) {
      if (["editor", "collage-background"].includes(e.target.className)) this.idxEditableContent = null;
      if (["editor", "collage-background"].includes(e.target.className)) this.idxEditableObject = null;
    },
    setEditableObject: function(idx) {
      this.idxEditableObject = idx;
    },
    deleteObject: function(idx) {
      this.editableCollage.objects[this.device].splice(idx, 1);
    },
    setEditableContent: function(idx) {
      this.idxEditableContent = idx;
    },
    addText: function() {
      this.editableCollage.texts.push({
        content: "",
        top: 100,
      });
      this.idxEditableContent = this.editableCollage.texts.length - 1;
    },
    addSound: function() {
      this.editableCollage.texts.push({
        content: "",
        sound: true,
        top: 100,
        left: 100,
      });
      this.idxEditableContent = this.editableCollage.texts.length - 1;
    },
    deleteText: function (idx) {
      this.editableCollage.texts.splice(idx, 1)
    },
    setCollage: function(idx) {
      this.idxEditableCollage = idx;
      this.idEditableCollage = [...this.collages].splice(idx, 1)[0].id;
    },
    createCollage: async function() {
      await api.createCollage();
      this.collages = await api.loadCollage();
    },
    saveCollage: async function() {
      await api.saveCollage(this.idEditableCollage, this.editableCollage);
      this.isSaved = true
    },
    deleteCollage: async function(idx) {

      const confirm =  prompt('Для удаления напишите: DELETE')

      if (confirm !== "DELETE") return false;
      await api.deleteCollage([...this.collages].splice(idx, 1)[0].id);
      this.collages = await api.loadCollage();
    },
    scrollEvent: function () {},
  },
};
</script>

<style lang="sass">
.editor-collage
  .editor
    position: relative
    padding-top: 50px
    padding-bottom: 50px

    //&__list

    &__collage
      position: absolute
      height: calc(100vh - 150px)
      transform: translate(-50%, -50%)
      top: 50%
      left: 50%
      border: 1px solid #5977c7

      &.--desktop
        width: 1800px

      &.--tablet
        width: 1170px

      &.--mobile
        width: 600px
        height: 85vh
        max-height: 824px
        min-height: 500px

    &__form
      position: absolute
      background: #f2f2f2
      width: 350px
      bottom: 90px
      left: 20px
      padding: 20px 15px
      box-shadow: 0 0 1px rgba(0, 0, 0, .3)

      &.--text
        width: 300px
        right: 20px
        left: auto


  .collage
    height: 100%
</style>
