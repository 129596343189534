<template>
  <div class="num-input">
    <label v-if="label" class="num-input__label">{{ label }}</label>
    <div class="num-input__field">
      <input
        type="email"
        class="num-input__control"
        :value="value"
        @input="e => $emit('input', e.target.value)"
        @keydown.up.exact.stop="e => $emit('input', +e.target.value + 1)"
        @keydown.up.shift.stop="e => $emit('input', +e.target.value + 10)"
        @keydown.down.exact.stop="e => $emit('input', +e.target.value - 1)"
        @keydown.down.shift.stop="e => $emit('input', +e.target.value - 10)"
      />
      <div class="num-input__dimension">{{ dimension }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VInputNum",
  props: ["value", "label", "dimension"],
};
</script>

<style lang="sass">
.num-input
  display: flex
  flex-direction: column
  margin-bottom: 10px
  &__label
    margin-bottom: 5px
    font-size: 11px
  &__field
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
  &__control
    width: 100%
    background: #3E3E46
    border: none
    color: #ffffff
    padding: 5px 2px
    text-align: center
    outline: 1px solid #3E3E46
    font-size: 13px
  &__dimension
    padding: 0 10px 0 10px
    color: #888
    font-size: 13px
</style>
